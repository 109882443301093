import { initVal } from '@valbuild/next';

const { s, c, val, config } = initVal({
  project: 'blankoslo/blankno-v3',
  root: '/web',
  gitBranch: process.env.VERCEL_GIT_COMMIT_REF,
  gitCommit: process.env.VERCEL_GIT_COMMIT_SHA,
  defaultTheme: 'dark',
});

export type { t } from '@valbuild/next';
export { s, c, val, config };
