import '../styles/globals.css';
import { ValProvider } from '@valbuild/next';
import { config } from '../val.config';

function MyApp({ Component, pageProps }) {
  return (
    <ValProvider config={config} disableRefresh>
      <Component {...pageProps} />
    </ValProvider>
  );
}

export default MyApp;
